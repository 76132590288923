




import { Component, Vue } from "vue-property-decorator";
import UploadDocument from "@/components/UploadDocument.vue";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import { checkFileSizeBeforeUpload } from "@/helper/files";
import { UploadConfiguration } from "@/enums/UploadConfiguration";

const StudentModule = namespace("student");
const DocumentModule = namespace("document");

@Component({
  components: { UploadDocument },
})
export default class StudentUploadQrcodeDocument extends mixins(TabMixin) {
  public name = "StudentUploadQrcodeDocument";
  public componentVisible = false;

  @DocumentModule.Action("uploadDocument")
  public uploadDocument: any;

  @DocumentModule.Getter("getIsLoading")
  public isLoading: any;

  @DocumentModule.Getter("getSuccess")
  public success: any;

  @DocumentModule.Getter("getError")
  public error: any;

  private fileSizeInvalid = false;

  public get visible() {
    return this.componentVisible;
  }
  public mounted() {
    this.componentVisible = true;
  }
  public async onSelectFile(files: Array<File>): Promise<void> {
    let formData = new FormData();
    this.fileSizeInvalid = !checkFileSizeBeforeUpload(files[0], UploadConfiguration.SIZE_MULTI_PAGES);
    if (this.fileSizeInvalid) return;

    formData.append("file", files[0]);
    await this.uploadDocument({
      resource: "documents/upload-scanned-document",
      data: formData,
    });
    if (this.success) this.$toasted.success("Success!");
    else if (this.error) {
      if (this.error.response && this.error?.response?.status === 422) {
        const message = this.error.response.data.globalErrors[0].error || this.error.response.data.fieldErrors[0];
        this.$toasted.error(message);
      } else {
        this.$toasted.error(this.error.message);
      }
    }
  }
  public abort() {
    this.componentVisible = false;
    this.fileSizeInvalid = false;
    this.closeCurrentTab();
  }
}
